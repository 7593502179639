.home-wolf {
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.home-wolf img {
    margin: auto;
    width: 100%;
    z-index: 1001;
}