@media (max-width: 1400px) {
    .home-page-nav > * {
        font-size: 1.5vw !important;
    }
    .company-footer-info {
        font-size: 1.7vw;
    }
    .company-footer-info-name {
        font-size: 2.4vw;
    }
    .contact-form-title {
        font-size: 2vw !important;
    }
    .contact-form-description {
        font-size: 1.3vw !important;
    }
    .contact-form label {
        font-size: 0.9vw !important;
    }
    .service-subsite-question {
        font-size: 2.4vw;
    }
    .service-subsite-answer {
        font-size: 1.7vw;
    }
    .customer-help-question {
        font-size: 2.4vw;
    }
    .customer-help-answer {
        font-size: 1.7vw;
    }
}

@media (max-width: 800px) {
    .home-page-nav {
        display: none !important;
    }

    .main-header {
        display: flex;
        justify-content: space-evenly;
    }

    .company-logo {
        width: 100%;
        flex: 3;
    }

    .main-header > .language-selector {
        flex-direction: column;
        flex: 1.5;
    }

    .main-header > .language-selector {
        margin: auto;
        justify-content: space-evenly;
        align-items: center;
    }

    .main-header > .language-selector > .language-selector-flag {
        width: 3.5vw;
    }

    .main-header > .search-bar-container {
        flex: 1;
    }
    .search-bar-ul {
        max-width: 50vw;
    }

    .smallnav-icon {
        display: inline-block;
        width: 25px;
        height: 20px;
        margin: auto 0;
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
    }

    .smallnav-icon:before {
        content: "";
        position: absolute;
        width: 25px;
        height: 2px;
        margin: 7px 0;
        background-color: #000;
        border-radius: 0.2em;
        transition: all 0.3s;
    }

    .smallnav-icon:hover {
        cursor: pointer;
    }

    .sidebar-nav.active > .home-page-nav {
        gap: 3vh;
        padding: 2vh 2vw;
    }

    .dropdown-nav {
        display: block;
        position: relative;
        font-size: 3vw !important;
    }

    .dropdown-nav-menu {
        z-index: 1020;
        padding-top: 0 !important;
        width: 55vw;
        transform: translateX(-100%) translateY(-20%) !important;
        text-align:left;
        position: absolute;
        overflow: hidden;
    }

    .dropdown-nav-menu a {
        white-space: normal;
        text-overflow: clip;
        overflow: hidden;
        margin: 10px 0;
        padding-left: 2.5vw;
        margin-left: 1vw;
        border-left: 2px solid #000;
    }

    .social-footer {
        max-width: 100%;
    }
    .social-footer a img {
        width: 4vw;
    }

    .contact-form-title {
        font-size: 5.25vw !important;
    }
    .contact-form-description {
        font-size: 3.5vw !important;
    }
    .contact-form label {
        font-size: 2.75vw !important;
    }
    .contact-form {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(18, 0.25fr 0.25fr);
        gap: 0.1rem;
        width: 75%;
        margin: auto;
    }
    .contact-form label {
        margin-top: 0.5vh;
    }
    .contact-form input {
        padding: 0.1vh;
    }
    .contact-form textarea {
        padding: 0.1vh;
    }
    .lastName, .firstName, .email, .phone, .company, .description, .submit {
        grid-column: 1;
    }
    label.lastName {
        grid-row: 3;
    }
    input.lastName {
        grid-row: 4;
    }
    label.firstName {
        grid-row: 5;
    }
    input.firstName {
        grid-row: 6;
    }
    label.phone {
        grid-row: 7;
    }
    input.phone {
        grid-row: 8;
    }
    label.email {
        grid-row: 9;
    }
    input.email {
        grid-row: 10;
    }
    label.company {
        grid-row: 11;
    }
    input.company {
        grid-row: 12;
    }
    label.description {
        grid-row: 13;
    }
    textarea.description {
        grid-row: 14 / 17;
    }
    .submit {
        grid-row: 18;
    }
    .service-subsite-question {
        font-size: 2.5vw !important;
        margin: 1rem 0 !important;
    }
    .service-subsite-answer {
        font-size: 1.9vw !important;
        margin: 0 0 1rem 0 !important;
        font-family: 'Times New Roman';
    }
    .customer-help-question {
        font-size: 2.4vw;
    }
    .customer-help-answer {
        font-size: 2.4vw;
    }
}
