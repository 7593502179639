.service-subsite-container {
    margin: 0 auto;
    display: flex;
    /* flex-direction: column; */
}

.service-subsite-subcontainer {
    display: grid;
    /* grid-template-areas:
    "title title"
    "text image"; */
    grid-template-areas:
    "title"
    "image"
    "text";
    margin: 0 0.5rem;
    padding: 0 1rem;
    grid-template-rows: auto auto 1fr;
    gap: 0.5rem;
    border: 1px solid #143db9;
    border-radius: 10px;
}

/* .service-subsite-subcontainer:nth-child(even) {
    grid-template-areas:
    "title title"
    "image text";
} */

.service-subsite-subcontainer a {
    text-decoration: none;
    font-size: 2rem;
    text-align: center;
    grid-area: title;
    /* margin: 0 0 2rem 0; */
}

.service-subsite-subcontainer-text {
    font-size: 0.95rem;
    grid-area: text;
    align-self: center;
}

.service-subsite-subcontainer-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-subsite-subcontainer .deco-box img {
    grid-area: image;
    width: 10rem;
    object-fit: cover;
    border-radius: 50%;
}

.service-subsite-content {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.service-subsite-question {
    font-size: 2rem;
    margin: 2rem 0;
    color: #143db9;
    font-weight: 700;
}

.service-subsite-answer {
    font-size: 1.1rem;
    margin: 0 0 2rem 0;
}