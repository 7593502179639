.contact-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 0.33rem;
}

.contact-form-title {
    font-size: 1.75rem;
    grid-row: 1;
    grid-column: 1 / 3;
    text-align: center;
}

.contact-form-description {
    font-size: 1.1rem;
    grid-column: 1 / 3;
    grid-row: 2;
    text-align: center;

}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
}

.contact-form label {
    font-size: 0.95rem;
}

.contact-form textarea {
    font-size: 0.5rem;
}

.lastName, .firstName, .email, .phone {
    grid-column: 1 / 2;
}

.company, .description, .submit {
    grid-column: 2 / 3;
}

label.company {
    grid-row: 3 / 4;
}

.company {
    grid-row: 4 / 5;
}

label.description {
    grid-row: 5 / 6;
}

.description {
    grid-row: 6 / 10;
}

button.submit {
    font-family: 'SpectralSC';
    font-size: 0.65rem;
}