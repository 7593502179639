.footer {
    width: 100%;
    display: flex;
    color: #000;
    padding: 1.2vw 3vw 1.2vw 3vw;
    gap: 2vw;
}

.company-footer {
    text-align: left;
    flex: 1;
    max-width: 30%;
}

.company-footer-info-container {
    text-align: left;
    line-height: 1.2;
    font-size: 1.1vw;
}

.company-footer-title {
    font-size: 3vw;
    font-weight: 700;
    text-align: left;
    margin-top: 3vh;
    margin-bottom: 3vh;
    line-height: 1.0;
}

.company-footer-info-name {
    font-size: 1.5vw;
    margin-top: 0.5vh;
    font-weight: 700;
    line-height: 1.0;
}

.company-footer-info-container .company-footer-info a {
    color: #1ea5dd;
}

.social-footer {
    display: none;
    margin-top: 2.5vh;
    justify-content: center;
    gap: 2.5vw;
    max-width: 60%;
}

.social-footer a img {
    width: 2rem;
}

.customer-help-container {
    flex: 1;
}
