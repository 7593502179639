.sidebar-nav {
    opacity: 0;
    visibility: hidden;
    z-index: 1010;
    transform: translateX(100%);
    transition: transform 0.7s ease-in-out;
}

.sidebar-nav.active {
    opacity: 1;
    visibility: visible;
    position: fixed; /* or 'absolute' */
    top: 0;
    right: 0;
    padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    transform: translateX(0);
    background-color: #fff;
    /* other styles as needed */
}

.sidebar-nav.active > .home-page-nav {
    display: inline-flex !important;
    flex-direction: column;
    margin: 0 !important;
    align-items: flex-start;
}

.sidebar-nav.active .home-page-nav>* {
    text-align: left;
}