.language-selector {
    display: flex;
    flex-direction: row;
    gap: 1vh;
    margin: auto;
    justify-content: center;
}

.language-selector-flag {
    width: 1.5vw;
    height: auto;
    cursor: pointer;
}