.slogan-text {
    display: inline-block;
    font-family: "RegularBrush";
    font-size: 6vw;
    position: absolute;
    z-index: 1010;
    color: #f2cb94;
    top: 84%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    animation: 
        typewriter 4s steps(44) 1s 1 normal both,
}

.slogan-text span {
    opacity: 0;
    animation: fadeIn 0.5s forwards infinite alternate;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}