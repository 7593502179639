.customer-help-title {
    font-size: 3vw;
    font-weight: 700;
    text-align: center;
    margin-top: 3vh;
    margin-bottom: 3vh;
    line-height: 1.0;
}

.customer-help-question {
    display: block;
    font-size: 1.2vw;
    color: #143db9;
    font-weight: 1000;
    margin-top: 1.5vh;
    line-height: 1.0;
}

.customer-help-answer {
    display: block;
    font-size: 1.2vw;
    color: #000;
    line-height: 1.0;
    margin-bottom: 3vh;
}
