.subsite-back-button {
    font-size: 1.5rem;
    margin: 0.5rem;
    text-decoration: none;
    background-color: transparent;
    border: none;
    color: #143db9;
}

.subsite-back-button:hover {
    color: #000;
    text-shadow: 2px 2px 3px #1ea5dd, 5px 5px 10px #143db9 ;
    cursor: pointer;
}

.subsite-back-text {
    font-size: 1.75vh;
}