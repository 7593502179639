@font-face {
  font-family: SpectralSC;
  src: url('../public/spectralsc.ttf');
}

@font-face {
  font-family: RegularBrush;
  src: url('../public/regularbrush.otf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 1px solid red !important; */
}

body {
  font-family: 'Cambria', 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  overflow-x: hidden;
}

.page-container {
  width: 100%;
  height: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 995;
}

.company-slogan {
  position: absolute;
  top: 20%;
  right: 3.5%;
  z-index: 1002;
  width: 25rem;
}

.company-footer-info-name, .nav-link {
  font-family: SpectralSC;
}

.small-nav {
  display: none;
}

mark {
  background-color: rgb(255, 255, 127);
}