.search-bar-ul {
    position: absolute;
    max-width: 25vw;
    list-style-type: none;
    margin: 0;
    padding: 1vw 1vw 1vw 0;
    white-space: nowrap;
    overflow: hidden;
    background-color: white;
}

.search-bar-li {
    margin: 0.5vw 0;
}

.search-bar-a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
    color: black;
}

.search-bar-a:hover {
    background-color: #f1f1f1;
}

.search-bar-a:visited {
    color: black;
}