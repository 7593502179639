  .home-page-nav {
    display: inline-flex;
    flex-direction: row;
    gap: 2vw;
  }

  .home-page-nav>* {
    flex: 1;
    text-align: center;
    font-size: 1.25rem;
    text-decoration: underline;
  }
  
  .home-page-nav .nav-link {
    position: relative;
    color: #000;
    transition: ease-in-out 0.2s;
    z-index: 1003;
  }

  .home-page-nav .nav-link:hover {
    color: #143db9;
    font-weight: 700;
  }

  .dropdown-nav {
    position: relative;
    display: inline-block;
  }

  .dropdown-nav-menu {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: auto;
    z-index: 1002;
    text-align: left;
    background-color: #fff;
    padding: 0.5rem;
    padding-top: 1.5rem;
    white-space: nowrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow: hidden;
    transform: translateY(-200%);
    transition: opacity 0.3s, visibility 0.3s, transform 1s;
  }

  .dropdown-nav-menu.active {
    display: block;
  }

  .dropdown-nav-menu a {
    display: block;
    color: #000;
    text-decoration: none;
    line-height: 2;
  }

  .dropdown-nav:hover .dropdown-nav-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }