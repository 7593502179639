body {
    position: relative;
    z-index: 0;
}

.main-header {
    display: flex;
    align-items: center;
    margin: 3vh 3vw 3vh 3vw;
    position: relative;
    gap: 2.5vw;
    z-index: 1003;
}

.main-header a {
    display: inline-block;
    height: fit-content;
    width: fit-content;
    margin: auto;
    flex: 2;
    z-index: 1003;
}

.home-page-nav {
    flex: 4;
}

.language-selector {
    flex: 1;
}

.company-logo {
    z-index: 1002;
    width: 15vw;
    vertical-align: middle;
}